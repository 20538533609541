import { defineStore } from 'pinia'
import { useAxios } from '@/Helpers/useAxios.js'

export const useAirportStore = defineStore('airportStore', () => {
    const availibleDepartureAirports = ref([])

    const loadData = (params) => {
        if (params.productType != 'pauschal') {
            return
        }
        useAxios.get('/api/static/departure-airports', params, {
            onSuccess: (response) => {
                availibleDepartureAirports.value = response
            }
        })
    }

    return {
        availibleDepartureAirports,
        loadData
    }
})
