import './bootstrap'
import '../css/app.css'

import { createApp } from 'vue/dist/vue.esm-bundler.js'
import { UtilitiesVue } from './Helpers/Utilities'
import i18n from './Services/Lang.js'
import Search from './Views/Search.vue'
import Offers from './Views/Offers/Index.vue'
import Offer from './Views/Offer/Show.vue'
import Cart from './Views/Cart/Index.vue'
import Checkout from './Views/Checkout/Index.vue'
import CheckoutSuccess from './Views/Checkout/Success.vue'
import Payment from './Views/Payment/Request.vue'
import PaymentResponse from './Views/Payment/Response.vue'
import Booking from './Views/Booking/Request.vue'
import BookingCheck from './Views/Booking/Check.vue'
import BookingVerify from './Views/Booking/Verify.vue'
import Wishlist from './Views/Wishlist/Show.vue'
import Newsletter from './Views/Newsletter.vue'
import SkeletonTrips from './Components/SkeletonTrips.vue'
import WhishListCount from './Components/WhishListCount.vue'
import Calendar from './Components/Global/Calendar.vue'
import Loading from './Components/Theme/Loading.vue'
import ContactForm from './Components/Snippet/ContactForm.vue'
//import Trips from './Views/Trips/Index.vue'
import router from './Routes/index.js'
import { createPinia } from 'pinia'

const app = createApp({
    components: {
        Calendar,
        Search,
        Offers,
        Offer,
        Cart,
        Checkout,
        CheckoutSuccess,
        Payment,
        PaymentResponse,
        Booking,
        BookingCheck,
        BookingVerify,
        Wishlist,
        Newsletter,
        WhishListCount,
        SkeletonTrips,
        Loading,
        ContactForm
        // Trips,
    },
    delimiters: ['${', '}']
})
    .use(i18n)
    .use(createPinia())
    .use(router)
    .use(UtilitiesVue)
app.mount('#app')

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR')
    })
}
