import { defineStore } from 'pinia'
import HotelForm from '@/components/SearchFormHotel.vue'
import TripForm from '@/components/SearchFormTrip.vue'
import PauschalForm from '@/components/SearchFormPauschal.vue'
import { useI18n } from 'vue-i18n'

export const useSearchStore = defineStore('search', () => {
    const router = useRouter()
    const route = useRoute()

    const allowSearch = ref(['PauschalForm', 'HotelForm', 'TripForm'])

    const activeTabIndex = ref(0)

    const productType = ref(null)

    const { t } = useI18n()

    const availibeSearch = [
        {
            productType: 'pauschal',
            title: t('Pauschal'),
            name: 'PauschalForm',
            icon: 'icon-plane-up',
            component: PauschalForm
        },
        {
            productType: 'hotel',
            title: t('Hotelonly'),
            name: 'HotelForm',
            icon: 'icon-bed',
            component: HotelForm
        },
        {
            productType: 'trips',
            title: t('Trips'),
            name: 'TripForm',
            icon: 'icon-luggage',
            component: TripForm
        }
    ]

    const tabs = computed(() => {
        return availibeSearch.filter((item) => {
            return allowSearch.value.includes(item.name)
        })
    })

    const selectTab = (index) => {
        activeTabIndex.value = index
    }

    const activeTab = computed(() => {
        return tabs.value[activeTabIndex.value]
    })

    const isPauschalForm = computed(() => {
        return activeTab.value?.name === 'PauschalForm' || productType.value === 'pauschal'
    })

    onMounted(() => {
        router.isReady().then(async () => {
            const searchTypes = route.query.search_filter ?? []
            const searchTypeSelected = searchTypes.filter((item) => item.selected).map((item) => item.value)
            allowSearch.value = allowSearch.value.filter((item) => searchTypeSelected.includes(item))
            productType.value = route.query.productType ?? null
        })
    })

    return {
        tabs,
        activeTab,
        activeTabIndex,
        selectTab,
        isPauschalForm
    }
})
