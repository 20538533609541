import { defineStore } from 'pinia'

export const useCartStore = defineStore('Cart', () => {
    const cart = ref({})

    const isLoading = ref(false)

    const create = () => {
        return axios
            .post('/api/cart/create')
            .then((response) => {
                localStorage.setItem('cart_session_id', response.data.cart_session_id)
                axios.defaults.headers.common['X-CART-SESSION-ID'] = localStorage.getItem('cart_session_id')
            })
            .catch((error) => {
                throw error
            })
    }

    const loadData = () => {
        isLoading.value = true
        return axios
            .get('/api/cart')
            .then((response) => {
                cart.value = response.data
                return response.data
            })
            .catch((error) => {
                throw error
            })
            .finally(() => {
                isLoading.value = false
            })
    }

    const items = computed(() => Object.values(cart.value.items ?? {}))

    const itemOffer = computed(() => items.value.find((item) => item.product === 'offer')?.details || null)

    return {
        isLoading,
        cart,
        itemOffer,
        items,
        full_payment_amount: computed(() => cart.value.full_payment_amount ?? 0),
        full_payment_at: computed(() => cart.value.full_payment_at ?? null),
        reservation_amount: computed(() => cart.value.reservation_amount ?? 0),
        total: computed(() => cart.value.total ?? 0),
        create,
        loadData
    }
})
